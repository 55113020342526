import { OBJECT_KEYS, COMPANY_SLUGS } from "common/constants";
import { getPercent } from "common/utils";

export const dataBreadcrumbs = (t) => [
  {
    label: t("planning:title"),
    path: "/planning",
  },
  {
    label: t("planning:customize_process"),
    path: "",
  },
];

export const EVALUATION_TYPE = {
  AUTOEVALUATION: "AutoevaluationTemplate",
  MANAGER: "ManagerEvaluationTemplate",
  PAIRS: "PairsEvaluationTemplate",
  COLLABORATORS: "CollaboratorsEvaluationTemplate",
  OVERALL: "OverallEvaluationTemplate",
};

export const getDefaultStakeholders = (t) => ({
  auto: {
    checked: false, label: t("tables:headers.Autoevaluation"), key: "auto", type: EVALUATION_TYPE.AUTOEVALUATION, value: 0, total: 0,
  },
  manager: {
    checked: false, label: t("tables:headers.ManagerEvaluation"), key: "manager", type: EVALUATION_TYPE.MANAGER, value: 0, total: 0,
  },
  peers: {
    checked: false, label: t("tables:headers.PairsEvaluation"), key: "peers", type: EVALUATION_TYPE.PAIRS, value: 0, total: 0,
  },
  team: {
    checked: false, label: t("tables:headers.CollaboratorsEvaluation"), key: "team", type: EVALUATION_TYPE.COLLABORATORS, value: 0, total: 0,
  },
});

export const getTemplateName = (actualName) => {
  const now = new Date();
  const año = now.getFullYear();
  const mes = String(now.getMonth() + 1).padStart(2, "0");
  const dia = String(now.getDate()).padStart(2, "0");
  const hora = String(now.getHours()).padStart(2, "0");
  const minutos = String(now.getMinutes()).padStart(2, "0");
  const segundos = String(now.getSeconds()).padStart(2, "0");

  return `${actualName} ${año}${mes}${dia}${hora}${minutos}${segundos}`;
};

const getEvaluationPercentValue = (evaluations, type) => {
  const evaluation = evaluations?.find((singleEvaluation) => singleEvaluation.type === type);
  return evaluation ? getPercent(evaluation.weighing) : 0;
};

export const mapEvaluationsToSections = (evaluations, sectionsTemplate) => {
  const sectionsMap = new Map();

  evaluations?.forEach((evaluation) => {
    evaluation.evaluation_section_templates.forEach((section) => {
      if (sectionsMap.has(section.id)) {
        const existingSection = sectionsMap.get(section.id);
        const evaluationExistingSection = section.evaluation_section_detail_templates.find(
          (detail) => detail.evaluation_template_id === evaluation.id,
        );

        existingSection.evaluations.push({
          id: evaluation.id,
          section_id: evaluationExistingSection.id,
          type: evaluation.type,
          weighing: evaluationExistingSection?.weighing
            ? getPercent(evaluationExistingSection.weighing) : 0,
        });
      } else {
        const questions = section.evaluation_question_templates.map((question) => ({
          id: question.id,
          name_es: question.name_es,
          name_en: question.name_en,
          name_pt: question.name_pt,
          position: question.position,
          evaluation_scale_id: question.evaluation_scale_id,
        }));

        questions.sort((a, b) => a.position - b.position);

        const evaluationExistingSection = section.evaluation_section_detail_templates.find(
          (detail) => detail.evaluation_template_id === evaluation.id,
        );
        const newSection = {
          id: section.id,
          name_es: section.name_es,
          name_en: section.name_en,
          name_pt: section.name_pt,
          questions,
          open: section.open,
          position: section.position,
          evaluations: [{
            id: evaluation.id,
            section_id: evaluationExistingSection.id,
            type: evaluation.type,
            weighing: evaluationExistingSection?.weighing
              ? getPercent(evaluationExistingSection.weighing) : 0,
          }],
        };

        sectionsMap.set(section.id, newSection);
      }
    });
  });

  sectionsTemplate.forEach((section) => {
    if (!sectionsMap.has(section.id)) {
      sectionsMap.set(section.id, {
        id: section.id,
        name_es: section.name_es,
        name_en: section.name_en,
        name_pt: section.name_pt,
        questions: section.evaluation_question_templates.map((question) => ({
          id: question.id,
          name_es: question.name_es,
          name_en: question.name_en,
          name_pt: question.name_pt,
          position: question.position,
          evaluation_scale_id: question.evaluation_scale_id,
        })),
        open: section.open,
        position: section.position,
        evaluations: section.evaluation_section_detail_templates?.map((detail) => ({
          id: detail.evaluation_template_id,
          section_id: detail.id,
          type: detail.evaluation_template_type,
          weighing: detail.weighing ? getPercent(detail.weighing) : 0,
        })) || [],
      });
    }
  });

  const sections = Array.from(sectionsMap.values());
  sections.sort((a, b) => a.position - b.position);

  return sections;
};

export const getTemplateValues = (template, sectionsTemplate) => ({
  type: template.type,
  nala_default: false,
  name_es: template.nala_default ? getTemplateName(template.name_es) : template.name_es,
  name_en: template.nala_default ? getTemplateName(template.name_en) : template.name_en,
  name_pt: template.nala_default ? getTemplateName(template.name_pt) : template.name_pt,
  description_es: template.description_es,
  description_en: template.description_en,
  description_pt: template.description_pt,
  min_worked_days: template.min_worked_days,
  reminder_days: template.reminder_days,
  auto: getEvaluationPercentValue(template.evaluation_templates, EVALUATION_TYPE.AUTOEVALUATION),
  manager: getEvaluationPercentValue(template.evaluation_templates, EVALUATION_TYPE.MANAGER),
  peers: getEvaluationPercentValue(template.evaluation_templates, EVALUATION_TYPE.PAIRS),
  team: getEvaluationPercentValue(template.evaluation_templates, EVALUATION_TYPE.COLLABORATORS),
  checkbox_auto: getEvaluationPercentValue(template.evaluation_templates, EVALUATION_TYPE.AUTOEVALUATION) > 0,
  checkbox_manager: getEvaluationPercentValue(template.evaluation_templates, EVALUATION_TYPE.MANAGER) > 0,
  checkbox_peers: getEvaluationPercentValue(template.evaluation_templates, EVALUATION_TYPE.PAIRS) > 0,
  checkbox_team: getEvaluationPercentValue(template.evaluation_templates, EVALUATION_TYPE.COLLABORATORS) > 0,
  sections: mapEvaluationsToSections(template.evaluation_templates, sectionsTemplate),
});

export const getPeersEvaluationText = (t) => {
  const companySlug = localStorage?.user && JSON.parse(localStorage.user).user?.company.slug;
  const evaluationName = companySlug && companySlug === COMPANY_SLUGS.onelink
    ? OBJECT_KEYS.human_resources : OBJECT_KEYS.pairs;
  return t(`surveys:evaluation_types.${evaluationName}`);
};

export const getEvaluationType = (type, i18n) => {
  const getT = (lang) => i18n.getFixedT(lang);

  const evaluationTypes = {
    en: "",
    es: "",
    pt: "",
  };

  Object.keys(evaluationTypes).forEach((lang) => {
    const t = getT(lang);
    switch (type) {
    case EVALUATION_TYPE.AUTOEVALUATION:
      evaluationTypes[lang] = t("surveys:evaluation_types.self_evaluation");
      break;
    case EVALUATION_TYPE.MANAGER:
      evaluationTypes[lang] = t("surveys:evaluation_types.manager");
      break;
    case EVALUATION_TYPE.PAIRS:
      evaluationTypes[lang] = getPeersEvaluationText(t);
      break;
    case EVALUATION_TYPE.COLLABORATORS:
      evaluationTypes[lang] = t("surveys:evaluation_types.collaborators");
      break;
    case EVALUATION_TYPE.OVERALL:
      evaluationTypes[lang] = t("surveys:evaluation_types.overall");
      break;
    default:
      break;
    }
  });

  return evaluationTypes;
};

export const PERFORMANCE_EVALUATION_TYPES = [
  EVALUATION_TYPE.AUTOEVALUATION,
  EVALUATION_TYPE.MANAGER,
  EVALUATION_TYPE.PAIRS,
  EVALUATION_TYPE.COLLABORATORS,
];

export const getStakeholderTotalLabel = (type, i18n) => {
  const getT = (lang) => i18n.getFixedT(lang);
  const evaluationTypes = getEvaluationType(type, i18n);
  const totalLabels = {
    en: "",
    es: "",
    pt: "",
  };

  Object.keys(totalLabels).forEach((lang) => {
    const t = getT(lang);
    totalLabels[lang] = `${t("planning:totalWeighing")} ${evaluationTypes[lang]} ${t("planning:sections")}: `;
  });
  return totalLabels;
};

export const DEFAULT_STAKEHOLDERS_FIELDS = ["auto", "manager", "peers", "team"];
export const DEFAULT_STAKEHOLDERS_SWITCHS = ["checkbox_auto", "checkbox_manager", "checkbox_peers", "checkbox_team"];
const DEFAULT_TYPES = [
  {
    key: "auto",
    value: EVALUATION_TYPE.AUTOEVALUATION,
  },
  {
    key: "manager",
    value: EVALUATION_TYPE.MANAGER,
  },
  {
    key: "peers",
    value: EVALUATION_TYPE.PAIRS,
  },
  {
    key: "team",
    value: EVALUATION_TYPE.COLLABORATORS,
  },
];

const getEvaluationsArray = (data, template) => {
  const evaluationTemplates = template.evaluation_templates;
  const evaluations = [];
  const types = DEFAULT_TYPES;

  types.forEach((type) => {
    const includeStakeholder = data[type.key] !== 0 && data[type.key] !== "0";
    const evaluation = evaluationTemplates.find(
      (evaluationTemplate) => evaluationTemplate.type === type.value,
    );
    const evaluationValues = {
      type: type.value,
      weighing: Number(data[type.key]) / 100,
      has_comments: false,
    };

    if (includeStakeholder) {
      evaluations.push(
        template.nala_default || !evaluation?.id
          ? { ...evaluationValues }
          : { id: evaluation.id, ...evaluationValues },
      );
    } else if (evaluation?.id && !template.nala_default) {
      evaluations.push({ id: evaluation.id, _destroy: true });
    }
  });
  return evaluations;
};

const getEvaluationSection = (
  section,
  evaluationTemplates,
  formValues,
  isEdit,
) => {
  const { evaluations } = section;
  const evaluationsection = [];

  evaluations.forEach((evaluation) => {
    const evaluationTemplate = evaluationTemplates.find(
      (ev) => ev.type === evaluation.type,
    );

    const generalStakeholderKey = DEFAULT_TYPES.find(
      (type) => type.value === evaluation.type,
    ).key;

    // If the general stakeholder is not included, not include the section for this stakeholder
    if (formValues[`checkbox_${generalStakeholderKey}`] === false) {
      return;
    }

    const isActive = formValues[`checkbox_${evaluation.type}_${section.id}`];

    if (isEdit && evaluation.section_id !== undefined && !evaluation.isNew) {
      evaluationsection.push({
        id: evaluation.section_id,
        evaluation_template_id: evaluationTemplate?.id,
        ...(isActive
          ? {
            weighing: formValues[`weighing_${evaluation.type}_${section.id}`] / 100,
          }
          : { _destroy: true }),
      });
    } else if (isActive) {
      evaluationsection.push({
        evaluation_template_id: evaluationTemplate?.id,
        weighing: formValues[`weighing_${evaluation.type}_${section.id}`] / 100,
      });
    }
  });

  return evaluationsection;
};

const getQuestionsSection = (questions, isEdit) => questions.map((question) => {
  if (question?.isDeleted) {
    return { id: question.id, _destroy: true };
  }

  if (question?.isNew) {
    return {
      name_es: question.name_es?.trim(),
      name_en: question.name_en?.trim(),
      name_pt: question.name_pt?.trim(),
      evaluation_scale_id: question.evaluation_scale_id,
    };
  }

  return isEdit
    ? ({
      id: question.id,
      name_es: question.name_es?.trim(),
      name_en: question.name_en?.trim(),
      name_pt: question.name_pt?.trim(),
      evaluation_scale_id: question.evaluation_scale_id,
    }) : ({
      name_es: question.name_es?.trim(),
      name_en: question.name_en?.trim(),
      name_pt: question.name_pt?.trim(),
      evaluation_scale_id: question.evaluation_scale_id,
    });
});

export const formatDataToSend = (data, template) => ({
  survey_process_template: {
    name_es: data.name_es?.trim(),
    name_en: data.name_en?.trim(),
    name_pt: data.name_pt?.trim(),
    type: template.type,
    nala_default: false,
    evaluation_templates_attributes: getEvaluationsArray(data, template),
  },
});

export const formatDataSectionToSend = (
  data,
  movedItem,
  evaluations,
  formValues,
  isEdit = false,
) => {
  const formattedData = {
    evaluation_section_template: {
      name_es: data.name_es?.trim(),
      name_en: data.name_en?.trim(),
      name_pt: data.name_pt?.trim(),
      open: data.open || false,
      evaluation_question_templates_attributes: getQuestionsSection(data.questions, isEdit),
      evaluation_section_detail_templates_attributes: getEvaluationSection(
        data,
        evaluations,
        formValues,
        isEdit,
      ),
    },
  };

  if (movedItem) {
    formattedData.evaluation_section_template.position = movedItem;
  }
  return formattedData;
};

export const ensureTemplateSuffix = (type) => {
  if (!type.endsWith("Template")) {
    return `${type }Template`;
  }
  return type;
};

export const getFormattedScalesList = (scalesList, formLanguage) => {
  let formattedScalesList = {
    en: [],
    es: [],
    pt: [],
  };

  scalesList.forEach((scale) => {
    formattedScalesList = {
      en: [...formattedScalesList.en, { label: scale.name_en, value: scale.id }],
      es: [...formattedScalesList.es, { label: scale.name_es, value: scale.id }],
      pt: [...formattedScalesList.pt, { label: scale.name_pt, value: scale.id }],
    };
  });

  const customOption = {
    en: { label: "Custom", value: "custom" },
    es: { label: "Personalizada", value: "custom" },
    pt: { label: "Personalizado", value: "custom" },
  };

  formattedScalesList.en.push(customOption.en);
  formattedScalesList.es.push(customOption.es);
  formattedScalesList.pt.push(customOption.pt);

  return formattedScalesList[formLanguage];
};

export const transformData = (data) => ({
  section_title_es: data.name_es,
  section_title_en: data.name_en,
  section_title_pt: data.name_pt,
  section_questions: data.evaluation_question_templates_attributes.map((question, index) => ({
    id: index + 1,
    question_es: question.name_es,
    question_en: question.name_en,
    question_pt: question.name_pt,
  })),
});

export const transformToDefaultData = (formattedSectionData, newData) => {
  const updatedData = { ...formattedSectionData };

  updatedData.evaluation_section_template.name_es = newData.section_title_es;
  updatedData.evaluation_section_template.name_en = newData.section_title_en;
  updatedData.evaluation_section_template.name_pt = newData.section_title_pt;

  updatedData.evaluation_section_template.evaluation_question_templates_attributes = newData.section_questions.map(
    (question, index) => ({
      ...updatedData.evaluation_section_template.evaluation_question_templates_attributes[index],
      name_es: question.question_es,
      name_en: question.question_en,
      name_pt: question.question_pt,
    }),
  );

  return updatedData;
};

const hasEmptyNameFields = (item) => {
  const rootNames = ["name_es", "name_en", "name_pt"];

  const hasEmptyRootName = rootNames.some((name) => !item[name]);

  if (hasEmptyRootName) {
    return true;
  }

  const hasEmptyQuestionName = item.questions.some(
    (question) => rootNames.some((name) => !question[name]),
  );

  return hasEmptyQuestionName;
};

export const filterItemsWithEmptyNames = (data) => data.filter(hasEmptyNameFields);

export const validateSections = (sections) => {
  let hasEmptyNames = false;
  let hasInvalidClosedQuestions = false;

  const rootNames = ["name_es", "name_en", "name_pt"];

  for (let i = 0; i < sections.length; i += 1) {
    const section = sections[i];

    const allSectionNamesEmpty = rootNames.every((name) => !section[name]);
    if (allSectionNamesEmpty) {
      hasEmptyNames = true;
      break;
    }

    for (let j = 0; j < section.questions?.length; j += 1) {
      const question = section.questions[j];

      const allQuestionNamesEmpty = rootNames.every((name) => !question[name]);
      if (allQuestionNamesEmpty) {
        hasEmptyNames = true;
        break;
      }

      if (question.evaluation_scale_id === "") {
        hasInvalidClosedQuestions = true;
        break;
      }

      if (hasEmptyNames || hasInvalidClosedQuestions) {
        break;
      }
    }

    if (hasEmptyNames || hasInvalidClosedQuestions) {
      break;
    }
  }

  return { hasEmptyNames, hasInvalidClosedQuestions };
};
